/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, OnChanges } from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { MatExpansionPanelState, matExpansionAnimations } from '@angular/material/expansion';

/** An notification block that functions as and extends CDK's expandable functionality.  */
@Component({
  selector: 'of-inline-notification',
  templateUrl: './inline-notification.component.html',
  styleUrls: ['./inline-notification.component.scss'],
  animations: [matExpansionAnimations.bodyExpansion]
})
export class InlineNotificationComponent extends CdkAccordionItem implements OnChanges {
  /** The title that will appear at the top of the notification. */
  @Input('titleText') titleText = '';
  /** The url of the image that will appear on the side of the notification. */
  @Input('srcUrl')    srcUrl = '';
  /** The alt text for the image on the side of the notification. */
  @Input('altText')   altText = '';

  ngOnChanges() { }

  /** Expand the component in to an open state. */
  open() {
    if (this.expanded) {
      // Add a --needs-attention class
    } else {
      super.open();
    }
  }

  /** Return `expanded` or `collapsed` depending on the expandable status. */
  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }
}
