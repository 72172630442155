<div
  collapsedHeight="0"
  [class.expanded]="expandedChange | async"
  [@bodyExpansion]="getExpandedState()"

  role="region"
  [attr.aria-labelledby]="titleText || altText || 'Notification Panel'"
  #body

  class="inline-notification"
>
  <div class="media-object rounded-lg border-danger highlight-danger pad-interrupt">
    <img *ngIf="srcUrl" class="media-object__figure" [src]="srcUrl" [alt]="altText">
    <div class="media-object__body">
      <h2 *ngIf="titleText" class="media-object__title">{{titleText}}</h2>
      <ng-content class="media-object__content"></ng-content>
    </div>
  </div>
</div>
