import { AfterViewInit, ContentChild, Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * Use this directive to swap a Material input field back and forth between `password` and `text`, effectively allowing
 * the user to show or hide the field contents. This is ideal for hiding and showing passwords.
 *
 * **To use this directive, you must place it on a parent element, mark a child input with `#toggleableField`, and mark a
 * child item with `#toggleButton`.** By default the directive expects `#toggleableField` to be a `password` type input.
 *
 * @example
 * <mat-form-field class="form-field width-full" ofToggleableVisibility>
 *   <input #toggleableField matInput type="password" placeholder="Password" formControlName="password" >
 *   <mat-icon #toggleButton matSuffix color="faint" class="xs">visibility</mat-icon>
 * </mat-form-field>
 */
@Directive({
  selector: '[ofToggleableVisibility]'
})
export class ToggleableVisibilityDirective implements AfterViewInit {
  // Without the read parameter `@ContentChild` won't return an ElementRef with a nativeElement.
  // http://stackoverflow.com/a/39909203/4811678
  /** A ElementRef to an input field that is a child of the element tagged with `ofToggleableVisibility` */
  @ContentChild('toggleableField', { read: ElementRef }) toggleableField: ElementRef;
  /** A ElementRef that is a child of the element tagged with `ofToggleableVisibility`. The click listener attached to it. */
  @ContentChild('toggleButton', { read: ElementRef }) toggleButton: ElementRef;
  /** @ignore */
  private _visible = false;

  /** @ignore */
  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) { }

  /** If the required sub elements exist in the DOM, initialize. */
  ngAfterViewInit(): void {
    if (this.toggleableField && this.toggleButton) {
      this.init(this.toggleableField, this.toggleButton);
    } else {
      throw new SyntaxError('ofToggleableVisibility requires you mark children with #toggleableField and #toggleButton ElementRefs.');
    }
  }

  /** Take the toggleButton element provided and attach the click listener. */
  init(toggleableField: ElementRef, toggleButton: ElementRef) {
    this.renderer.listen(toggleButton.nativeElement, 'click', (event) => {
      this.toggleVisibility(toggleableField);
    });
  }

  /** Toggle the provided `input` element between a `text` and `password` type. */
  toggleVisibility(toggleableField: ElementRef) {
    this._visible = !this._visible;
    const newInputType = (this._visible) ? 'text' : 'password';
    const newInputClass = (this._visible) ? 'mat-faint' : 'dark-icon';
    // Change the icon color.
    if (this._visible) {
      this.renderer.setStyle(this.toggleButton.nativeElement, 'color', '#424242');
    } else {
      this.renderer.removeStyle(this.toggleButton.nativeElement, 'color');
    }
    // Change the field type.
    toggleableField.nativeElement.setAttribute('type', newInputType);
  }

}
